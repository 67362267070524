/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("title", null, "Hurricane Brown Eyes | Michael Weslander"), "\n", React.createElement(_components.h1, null, "Title: Hurricane Brown Eyes"), "\n", React.createElement(_components.h2, null, "Artist: Michael Weslander"), "\n", React.createElement(_components.h3, null, "Comfort: 2"), "\n", React.createElement(_components.h4, null, "Key: Em"), "\n", React.createElement(_components.p, null, "I hate that you can come along\nAnd break a love we've loved for so long\nAs an hour passes by\nHer name plays as my lullabye"), "\n", React.createElement(_components.p, null, "And you struggle just the same\nSo I'll never know what's going on\nWhat's going on"), "\n", React.createElement(_components.p, null, "Will I wait or will I fly\nWhen my mind turns green as the stormy sky\nI will wait and I will try\nAs my feet leave the ground\nCaught up in Hurricane\nBrown Eyes"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
